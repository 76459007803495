body {
  background: #f9f9f9;
  font: 12px/1.5 "\5FAE\8F6F\96C5\9ED1", "\5B8B\4F53", Arial, Tahoma, Helvetica, "SimSun", sans-serif;
}
* {
  box-sizing: border-box;
}
.breadcrumb > li + li:before {
  content: ">";
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
table .btn {
  border: 0 !important;
}
button[disabled] {
  background-color: #ccc !important;
  color: #ffffff;
}
.text-indent {
  text-indent: 30px;
}
.table > thead > tr > th {
  border-bottom: 1px solid #ddd;
}
label {
  font-weight: normal;
}
.selectric-wrapper .selectric {
  background: #ffffff;
  border-radius: 4px;
}
.selectric-wrapper .selectric span.label {
  font-weight: normal;
  text-align: left;
}
.selectric-wrapper .selectric-items {
  background: #fff;
}
.datepicker {
  text-indent: 8px;
}
.btn-primary,
.btn-buy,
.btn-default {
  border: none;
}
.arrow-up:after {
  content: '\2191';
}
.arrow-down:after {
  content: '';
}
.arrow {
  display: inline-block;
  width: 14px;
  height: 14px;
  text-align: center;
  font-size: 14px;
  color: #cccccc;
  margin-top: -1px;
}
#app .main-home {
  padding-top: 10px;
}
#app .main-home img {
  width: 100%;
}
#app .main-home .user-guide {
  padding: 0;
}
#app .main-home .user-guide h1,
#app .main-home .user-guide h2,
#app .main-home .user-guide p {
  padding: 0;
  margin: 0;
}
#app .main-home .user-guide h2 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 22px;
}
#app .main-home .user-guide p {
  font-size: 14px;
  color: #686868;
}
#app .main-home .user-guide .zy-info {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dedede;
  -ms-border: solid 1px #dedede;
  border-radius: 4px;
  text-align: center;
  padding: 18px 20px 18px;
  min-height: 246px;
  min-height: 210px \9;
}
#app .main-home .user-guide .zy-info h1 {
  font-size: 54px;
  color: #f0320f;
  margin: 7px 0 0px;
}
#app .main-home .user-guide .zy-info .btns {
  margin-top: 14px;
}
#app .main-home .user-guide .zy-info .btns .btn {
  width: 160px;
  height: 40px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid #f0320f;
}
#app .main-home .user-guide .zy-info .btns .buy {
  background: #f0320f;
  color: #fff;
  margin-right: 8px;
}
#app .main-home .user-guide .zy-info .btns .detail {
  background: #fff;
  color: #f0320f;
}
#app .main-home .user-guide .tips {
  padding: 0 0 0 10px;
}
#app .main-home .user-guide .tips .col-md-4 {
  padding: 0;
}
#app .main-home .user-guide .tips .zndt {
  margin-left: 0;
  margin-right: 5px;
}
#app .main-home .user-guide .tips .fxq {
  margin: 0 5px;
}
#app .main-home .user-guide .tips .qrcode {
  margin-left: 5px;
}
#app .main-home .user-guide .tips .qrcode h2 {
  text-align: left;
  position: relative;
}
#app .main-home .user-guide .tips .qrcode h2 span {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px;
  color: #cccccc;
}
#app .main-home .user-guide .tips .zndt,
#app .main-home .user-guide .tips .fxq,
#app .main-home .user-guide .tips .qrcode {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #dedede;
  border-radius: 4px;
  padding: 18px 12px 18px;
  text-align: center;
  min-height: 246px;
}
#app .main-home .user-guide .tips .zndt h2,
#app .main-home .user-guide .tips .fxq h2,
#app .main-home .user-guide .tips .qrcode h2 {
  margin-bottom: 0;
}
#app .main-home .user-guide .tips .zndt p img,
#app .main-home .user-guide .tips .fxq p img,
#app .main-home .user-guide .tips .qrcode p img {
  width: auto;
}
#app .main-home .user-guide .tips .zndt p.icon,
#app .main-home .user-guide .tips .fxq p.icon,
#app .main-home .user-guide .tips .qrcode p.icon {
  margin-bottom: 16px;
}
#app .main-home .user-guide .tips .zndt p.intro,
#app .main-home .user-guide .tips .fxq p.intro,
#app .main-home .user-guide .tips .qrcode p.intro {
  line-height: 24px;
  text-align: center;
}
#app .main-home .recommend {
  margin-top: 10px;
  padding: 20px 0;
  background: #ffffff;
  -ms-border: 1px solid #dedede;
  box-shadow: 0 0 0 1px #dedede;
}
#app .main-home .recommend .hot {
  position: relative;
}
#app .main-home .recommend .hot span.icon {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  content: " ";
  background: url(/static/img/hot.0291ab9.png) center / 24px 24px no-repeat;
  right: 8px;
  top: -25px;
}
#app .main-home .recommend h2,
#app .main-home .recommend h3 {
  padding: 0;
  margin: 0;
}
#app .main-home .recommend h2 {
  font-size: 20px;
  font-weight: 700;
  color: #3ba2ff;
  position: relative;
}
#app .main-home .recommend h2 span {
  font-size: 14px;
  color: #686868;
  display: inline-block;
  margin-left: 10px;
}
#app .main-home .recommend h3 {
  font-size: 20px;
  color: #f0320f;
  margin-top: 16px;
}
#app .main-home .recommend .no-border {
  border: none!important;
}
#app .main-home .recommend .col-md-3 {
  min-width: 193px;
  padding: 0 20px;
  border-right: 1px solid #dedede;
}
#app .main-home .recommend .col-md-3:last-child {
  border-right: none!important;
}
#app .main-home .recommend .col-md-3 .row {
  margin-top: 16px;
}
#app .main-home .recommend .col-md-3 .row a.history {
  display: inline-block;
  font-size: 16px;
  color: #333333;
  background: url(/static/img/open-arrow.663282b.png) center right no-repeat;
  padding: 0 16px 0 20px;
}
#app .main-home .recommend .col-md-3 .col-md-6 p,
#app .main-home .recommend .col-md-3 .col-md-6 h3 {
  padding: 0;
  margin: 0;
}
#app .main-home .recommend .col-md-3 .col-md-6 p {
  color: #686868;
  font-size: 14px;
  margin-bottom: 5px;
}
#app .main-home .recommend .col-md-3 .col-md-6:first-child {
  border-right: 1px solid #dedede;
}
#app .main-home .recommend .col-md-3 ul {
  padding: 0 0 0 20px;
  list-style-image: url(/static/img/arrow.bc52b6c.png);
}
#app .main-home .recommend .col-md-3 ul li {
  margin-top: 10px;
  font-size: 14px;
  color: #686868;
}
#app .main-home .recommend .col-md-3 .btns {
  text-align: center;
  margin-top: 6px;
}
#app .main-home .recommend .col-md-3 .btns .btn {
  width: 115px;
  height: 26px;
  font-size: 14x;
  border-radius: 4px;
  padding: 0;
  border: 1px solid #f0320f;
}
#app .main-home .recommend .col-md-3 .btns .buy {
  background: #f0320f;
  color: #fff;
  margin-right: 8px;
}
#app .main-home .recommend .col-md-3 .btns .detail {
  background: #fff;
  color: #f0320f;
}
#app .main-home .production {
  margin-top: 10px;
  padding: 0;
  background: #FFF;
}
#app .main-home .production .nav {
  border: none;
}
#app .main-home .production .nav li {
  border-radius: 0;
  background: none;
  width: 16.66666667%;
  text-align: center;
}
#app .main-home .production .nav li a {
  font-size: 16px;
  background: #fff;
  border: none;
  color: #686868;
  border-radius: 0;
}
#app .main-home .production .nav li.active a {
  background: #43ACFF;
  color: #FFF;
}
#app .main-home .production .tab-content {
  font-size: 13px;
  color: #333333;
}
#app .main-home .production .tab-content table {
  margin-bottom: 0;
}
#app .main-home .production .tab-content table thead {
  background: #DEF0FB;
  color: #6CA6CA;
}
#app .main-home .production .tab-content table thead th {
  text-align: center;
}
#app .main-home .production .tab-content table thead th[data-sort="float"] {
  cursor: pointer;
}
#app .main-home .production .tab-content table tbody td {
  vertical-align: middle;
  text-align: center;
}
#app .main-home .production .tab-content table tbody .green {
  color: #43B606;
}
#app .main-home .production .tab-content table tbody .red {
  color: #F0320F;
}
#app .main-home .production .tab-content table tbody .green {
  color: #43b606;
}
#app .main-home .production .tab-content table tbody .opt button[disabled] {
  background: #CCCCCC;
}
#app .main-home .production .tab-content table tbody .opt button {
  padding: 6px 11px;
  font-size: 14px;
  color: #FFF;
}
#app .main-home .production .tab-content table tbody .opt .sg {
  background: #F0320F;
  margin-right: 8px;
}
#app .main-home .production .tab-content table tbody .opt .dt {
  background: #F56723;
}
#app .main-home .tools {
  padding: 20px;
  background: #fff;
  margin-top: 10px;
  color: #333333;
}
#app .main-home .tools h2 {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
#app .main-home .tools .row {
  margin: 14px 0 0;
  padding: 0;
  cursor: pointer;
}
#app .main-home .tools .row .col-md-1 {
  text-align: center;
  padding: 0;
  width: 20%;
  background: url(/static/img/tools-col-line-bg.8335e71.png) center right no-repeat;
}
#app .main-home .tools .row .col-md-1 img {
  display: block;
  width: auto;
  margin: 0 auto;
}
#app .main-home .tools .row .col-md-1 h3 {
  color: #AAAAAA;
  font-size: 14px;
  padding: 0;
  margin: 12px 0 0;
}
#app .main-home .news {
  padding: 0;
  margin-top: 10px;
}
#app .main-home .news .col-md-6 {
  margin: 0 0 10px;
  padding: 0;
}
#app .main-home .news .col-md-6 .detail {
  min-height: 360px;
  margin-right: 10px;
  background: #FFF;
}
#app .main-home .news .col-md-6 .detail h2 {
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #0073CC;
  padding: 17px 20px 13px;
  position: relative;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAABCAYAAAFRb/rwAAAABGdBTUEAALGPC/xhBQAAAthJREFUSA29VdlyGzEMk5y4//+/sVcFIEDHel1PXrozGookwFNOaiu1Pcq9POu9PMp3eZY/kF3/abFbSqefuHv5Ec543GWv0IF7KB54si9xFP8bfuIQh1JxwFl02oad/qF3HvlP1gGZOo9bLfWrlIZTLM86KKXccCCb5dDDg73SHx3yV3Hexb2IozzEw8d89Vm+mpbh4WvIa/MaPofmIW7D4RDnsB8edl8uh9h5GjqGrSWc+YueIc9lcPiJDz7iaTEXy+Zy1iFz2NtQMyQ0neYpczJ86VlWhhR9kZc84+OjvIzrOGMZ1F0LFlJbA/PAM6HEgkrDczjQ0VFxIKnLHhz12C0nv8ehfjiO+G9wT+bdcM5Pvk/TozjhyFO9M9/QHW/Lb7zqVP3Mcxt9zPphd5+zf/5FuMgzcJlXl+pHtXuul/UwT3rsuAN9sg6UhdrwWPiITpL2K/9m53LJw8HYB/4j7xf5UteWd+G/s4vHh8o+WB/rJM9n5f3XepF/q4d1oc7KfyE4WlYDoqFC6gc7QYXxQRn3Bu7hTuKPhMs/NvIdlzFzTxwGwcOh4EMJv0vnH/YlFvmIt9fXa+OUyaePmFk//fAt/RFHv+IQv+TSHYXxB5S6AB539W9+n1eP3fO7f+W/iJs47n/mB5b5OKtgoDOv+kF9swbYXvpzv+YrbuqnLffEfuGn19Tc99Lzp7/p6/nn/nptc57dHzwlztZf9m+f6lpqcM3pP7vNbEZ/7jdvLfPc57PETf+Qe8ze49gnvKw3MxecFHwcHT+6ucbhoz0Y+6MPDOziB2v88H/gJ/cZf9aJw3NXPbrjSszIbX3jObdwhC982qinXz1fjoefcZLW4w9+YE5+qsPHOPjSo+Zr2wsGOK9HnPjDjS5JxKlGzcQ21Zp7cCuH91OvVxxSM9/0T+o5N/Xwgx8Y4//FT4+X7+8T37kJ858UXUd+NoFvnY8MtJNrf/B/ARj2DouC4jrHAAAAAElFTkSuQmCC') bottom right no-repeat;
}
#app .main-home .news .col-md-6 .detail h2 span {
  display: block;
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  color: #333;
  top: 50%;
  right: 20px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAFG9gWtAAAABGdBTUEAALGPC/xhBQAAAHFJREFUCB1jMDY2jmeAAyBvC4Onpyc7Ew8Pzx9GkLiJickKUVHReIbQ0FBmIGcbWDFYIZDFoqSk5M/IyAgRBallhGlgBGm4f//+UqAyPqAJgdu3b/8JNhJkAkzy////PGBBmABMNQPIZSBrQM4B6QABAIwfJcAAYt/nAAAAAElFTkSuQmCC') center right no-repeat;
  padding-right: 16px;
}
#app .main-home .news .col-md-6 .detail ul,
#app .main-home .news .col-md-6 .detail li {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
}
#app .main-home .news .col-md-6 .detail ul {
  padding: 20px 20px 10px;
}
#app .main-home .news .col-md-6 .detail ul li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #686868;
}
#app .main-home .news .col-md-6 .detail ul li a {
  color: #686868;
}
#app .main-home .news .col-md-6 .detail ul li .badge {
  background: none;
  border-radius: 0;
  font-weight: normal;
  color: #AAAAAA;
}
#app .main-home .services {
  padding: 20px 20px 15px;
  background: #fff;
  color: #333333;
}
#app .main-home .services h2 {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
#app .main-home .services ul,
#app .main-home .services li {
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  border-radius: 0;
}
#app .main-home .services ul {
  margin-top: 10px;
  list-style: none;
  clear: both;
}
#app .main-home .services ul li {
  display: block;
  float: left;
  width: 20%;
  padding: 5px 0;
}
#app .main-home .services ul li a {
  font-size: 14px;
  color: #AAAAAA;
}
