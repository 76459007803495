.footer {
  padding: 43px 0 35px;
  margin-top: 30px;
  background: #F0F0F0;
}
.footer .container {
  padding: 0;
  text-align: center;
}
.footer .container .row {
  color: #AAAAAA;
  font-size: 14ppx;
  padding: 10px 0;
}
.footer .container .row a {
  color: #AAAAAA;
}
.footer .container .linker a {
  display: inline-block;
  margin-left: 20px;
}
