.menu {
  padding: 0;
  background: #45A7FE;
  background-image: linear-gradient(-270deg, #43ACFF 0%, #39A0FE 100%);
  background-size: cover;
  font-size: 16px;
  color: #fff;
}
.menu .row li a {
  color: #fff;
  background: #45A7FE;
  background-image: linear-gradient(-270deg, #43ACFF 0%, #39A0FE 100%);
}
.menu .row li a:hover {
  background: #0073cc;
  border-radius: 0;
}
.menu .row li.open a {
  background: #0073CC;
  border-radius: 0;
}
.menu .row li.open ul {
  border-radius: 0;
  width: 100%;
  padding: 0;
  border: none;
  box-shadow: none;
  margin-top: 0;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAFoEvQfAAAABGdBTUEAALGPC/xhBQAAAA1JREFUCB1j+P///zMACeID5GQWRqkAAAAASUVORK5CYII=') repeat;
}
.menu .row li.open ul li {
  border-radius: 0;
  background: none;
  text-align: center;
}
.menu .row li.open ul li a {
  padding: 14px 0 12px;
  background: none;
  border-radius: 0;
  font-size: 14px;
  color: #0073CC;
}
.menu .row li.open ul li a:hover {
  background: #0073CC;
  border-radius: 0;
  color: #FFF;
}
.menu .row li.open ul .active {
  border-radius: 0;
  background: #0073CC;
}
.menu .row li.open ul .active a {
  color: #FFF;
}
.menu .row li.active > a {
  background: #0073cc;
  border-radius: 0;
}
