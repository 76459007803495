.logo-bar-fluid {
  background: #ffffff;
}
.logo-bar .logo {
  padding: 17px;
  cursor: pointer;
}
.logo-bar .guide .contact {
  text-align: right;
}
.logo-bar .guide .contact .phone {
  display: inline-block;
  padding-top: 31px;
  font-size: 16px;
  margin-right: 0;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #3ba2ff;
}
.logo-bar .guide .search {
  text-align: right;
  padding-top: 20px;
}
.logo-bar .guide .search input {
  width: 240px;
  height: 30px;
  background: url(/static/img/icon-search.a796113.png) 10px center no-repeat;
  border: solid 1px #dedede;
  padding-left: 30px;
}
