.banner {
  height: 270px;
  padding: 0;
  background: #476ae5;
}
.banner img {
  height: 270px;
  display: block;
  margin: 0 auto;
}
.banner .carousel {
  z-index: 0;
}
.banner .carousel img {
  height: 270px;
  width: 100%;
}
.banner .carousel .carousel-indicators {
  bottom: 0;
}
.banner .carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.6);
  margin-right: 15px;
  margin-top: 0;
}
.banner .carousel .carousel-indicators li.active {
  width: 20px;
  height: 10px;
  border-radius: 20px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.6);
}
.banner .login {
  position: relative;
  z-index: 1;
}
.banner .login .box {
  position: absolute;
  right: 0;
  top: 20px;
  padding: 0px 20px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAFoEvQfAAAABGdBTUEAALGPC/xhBQAAAA1JREFUCB1juH37diwACBUC71+1kqwAAAAASUVORK5CYII=") repeat;
}
.banner .login .box ul {
  border: none;
}
.banner .login .box ul li {
  background: none;
}
.banner .login .box ul li a {
  font-size: 14px;
  background: none;
  border: none;
  color: #686868;
}
.banner .login .box ul li.active {
  background: none;
}
.banner .login .box ul li.active a {
  color: #0073cc;
}
.banner .login .box .tab-content form {
  width: 200px;
}
.banner .login .box .tab-content form button {
  background: #3ba2ff;
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 8px 16px;
}
.banner .login .box .tab-content form select {
  margin-bottom: 10px;
}
.banner .login .box .tab-content form input {
  margin-bottom: 10px;
  font-size: 12px;
}
.banner .login .box .tab-content form .form-group input {
  margin-bottom: 0;
}
.banner .login .box .tab-content form .form-group .input-group-addon {
  padding: 0;
}
.banner .login .box .botton-opt {
  padding: 10px 0 15px;
  font-size: 12px;
  margin: 0;
}
.banner .login .box .botton-opt .col-md-7,
.banner .login .box .botton-opt .col-md-5 {
  padding: 0;
  color: #686868;
}
.banner .login .box .botton-opt .col-md-7 a,
.banner .login .box .botton-opt .col-md-5 a {
  color: #0073cc;
}
.banner .login .box .botton-opt .forgot-pass {
  text-align: right;
}
.banner .login .box .botton-opt .forgot-pass a {
  color: #686868;
}
.banner .selectric-wrapper {
  margin-bottom: 10px;
}
.banner .selectric-wrapper .selectric .label {
  height: 30px;
  line-height: 30px;
}
.banner .selectric-wrapper .selectric-scroll li.highlighted {
  background: #D0D0D0;
}
.banner .selectric-wrapper .selectric-scroll li:hover {
  background: #D0D0D0;
}
