.top-tips {
  padding: 0;
  box-shadow: 0 1px 0 0 #dedede;
  background-color: #eeeeee;
  line-height: 30px;
  font-size: 12px;
}
.top-tips .row a {
  display: inline-block;
  padding: 0 20px;
}
.top-tips .row span.split {
  padding: 0;
}
.top-tips .row .left a {
  color: #0073cc;
}
.top-tips .row .left span.split {
  color: #aaaaaa;
}
.top-tips .row .left:first-child {
  padding: 0;
}
.top-tips .row .right {
  cursor: pointer;
  text-align: right;
}
.top-tips .row .right a {
  padding: 0 12px;
}
.top-tips .row .right a,
.top-tips .row .right span.split {
  color: #686868;
}
