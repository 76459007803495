#modal-alert .modal-header {
  border-bottom: none;
  text-align: center;
  color: #3ba2ff;
}
#modal-alert .btn {
  padding: 12px 50px;
  color: #ffffff;
  font-size: 16px;
  background: #3ba2ff;
  line-height: 1;
}
#modal-alert .error {
  text-align: center;
  margin-top: -20px;
  font-size: 14px;
  color: #333333;
  padding-bottom: 20px;
}
#modal-alert .error img {
  margin-bottom: 20px;
}
#modal-alert .error .btn {
  margin-top: 30px;
}
